import { Cookie } from 'ng2-cookies';
import { useCallback } from 'react';
import { useForceRefreshToken } from 'app/hooks/use-force-refresh-token.hook';

interface UseCookieHook {
  (name: string, minutes?: number): [string, (newValue: string) => any, () => any];
}

export const useCookie: UseCookieHook = (name: string, minutes?: number) => {
  const value = Cookie.get(name);
  const [, forceRefresh] = useForceRefreshToken();

  const setValue = useCallback((newValue: string) => {
    const expireDate = new Date(new Date().getTime() + minutes * 60000);
    Cookie.set(name, newValue, minutes ? expireDate : null);
    forceRefresh();
  }, [name, minutes]);

  const deleteCookie = useCallback(() => {
    Cookie.delete(name);
    forceRefresh();
  }, [name]);
  return [value, setValue, deleteCookie];
};
